import styled from "styled-components";

export const Title = styled.h2`
    color: ${({ theme }) => theme.color.primaryBlue};
    font-size: ${({ theme }) => theme.font.size.heading};
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
`;

export const ContentWrapper = styled.div`
    display: grid;
`;

export const StyledIframe = styled.iframe`
    max-width: 560px;
`;

export const CarouselContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    .carousel-root {
        .carousel.carousel-slider {
            .control-dots {
                .dot {
                    background-color: ${({ theme }) => theme.color.primaryBlue};
                }
            }
            .control-arrow {
                opacity: 1;
                &:hover {
                    background: transparent;
                }
                &.control-next {
                    &::before {
                    border-left: 8px solid ${({ theme }) => theme.color.darkBlue};
                    }
                }

                &.control-prev {
                    &::before {
                    border-right: 8px solid ${({ theme }) => theme.color.darkBlue};
                    }
                }
            }

            .carousel-status {
                display: none;
            }
        }
    }
`;


export const StyledButtonContainer = styled.div`
    display: flex;
    width: 100%;
    text-align: center;
`;

export const StyledButton = styled.a`
  color: ${({ theme }) => theme.color.darkBlue};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.color.primaryBlue};
  font-weight: 700;
  width: 180px;
  padding: 10px 20px;
  transition: all 0.3s;
  margin: 40px auto;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.primaryBlue};
  }
`;