import React from "react";
import Contact from '../../components/Common/Contact/Contact'
import Hero from '../../components/Common/Hero/Hero'
import { AboutUs } from '../../components/GuitarLessonsPage/AboutUs/AboutUs'
import AccordionFaq from '../../components/GuitarLessonsPage/Faq/AccordionFaq'
import Opinions from '../../components/GuitarLessonsPage/Opinions/Opinions'
import { PhotoGallery } from '../../components/GuitarLessonsPage/PhotoGallery/PhotoGallery'
import { PriceList } from '../../components/GuitarLessonsPage/PriceList/PriceList'
import YoutubeSlider from '../../components/GuitarLessonsPage/YoutubeSlider/YoutubeSlider'

const GuitarLessons = () => (
    <>
        <Hero />
        <AboutUs />
        <PriceList />
        <PhotoGallery />
        <YoutubeSlider />
        <AccordionFaq />
        <Opinions />
        <Contact />
    </>
)

export default GuitarLessons;