import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const Title = styled.h2`
    color: ${({ theme }) => theme.color.primaryBlue};
    font-size: ${({ theme }) => theme.font.size.heading};
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &.second-content-wrapper {
        justify-content: flex-start;
    }

    @media screen and (min-width: 840px) {
        justify-content: space-between;
        flex-wrap: nowrap;
    }
`;

export const TextWrapper = styled.div`
    max-width: 460px;
    order: 2;

    @media screen and (min-width: 840px) {
        order: 1;

        &.second-content {
            order: 2;
        }
    }
`;

export const Paragraph = styled.p`
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.font.size.paragraph};
    text-align: justify;

    @media screen and (min-width: 840px) {
        text-align: left;
    }

    @media screen and (min-width: 1500px) {
        max-width: 800px;
    }
`;

export const ParagraphList = styled.ul`
    padding: 0;

    @media screen and (min-width: 840px) {
        max-width: 460px;
        order: 2;
        margin-left: 40px;
    }

    @media screen and (min-width: 1500px) {
        max-width: 800px;
    }
`;

export const ParagraphWithIcon = styled.li`
    list-style: none;
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.font.size.paragraph};
    font-size: 18px;
    display: flex;
    margin-bottom: 10px;

    ${Paragraph} {
        margin: 0;
    }

    svg {
        min-width: 28px;
    }
`;
export const AboutUsFooterTextContainer = styled.div`
    background-image: url('../../striped-background.svg');
    display: flex;
    align-items: center;

    ${Paragraph} {
        color: ${({ theme }) => theme.color.darkBlue};
        font-size: ${({ theme }) => theme.font.size.headingSmall};
        font-weight: 700;
        text-transform: justify;
        text-align: center;
        padding: 0 20px;
    }

    @media screen and (min-width: 840px) {
        height: 220px;
        margin-top: 40px;

        ${Paragraph} {
        padding: 0 140px;
        max-width: 100%;
        }
    }
`;

export const StyledImage = styled(GatsbyImage)`
        height: 440px;
        width: 560px;
        order: 1;
        margin: 0 auto;

        @media screen and (min-width: 840px) {
            order: 2;
            margin: 0;
            width: 440px;
            img {
                object-fit: contain !important;
            }

            &.second-content {
            order: 1;
            height: fit-content;
            padding-top: 40px;
            }
        }

        @media screen and (min-width: 1500px) {
            min-width: 440px;
        }
`;