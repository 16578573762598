import React from 'react';
import { SectionWrapper } from '../../../assets/styles/ThemeConfig';
import { data } from '../../../helpers/data';
import { Title, Wrapper, StyledDetails, AccordionDivider } from './AccordionFaq.styled';

const AccordionFaq = () => {
  return (
    <>
      <SectionWrapper>
        <Title>Faq</Title>
        <Wrapper>
          {data.map(item => (
            <div key={item.title}>
              <StyledDetails >
                <summary>{item.title}</summary>
                <div>
                  <p>{item.content}</p>
                </div>
              </StyledDetails>
              <AccordionDivider />
            </div>
          ))}
        </Wrapper>
      </SectionWrapper>
    </>

  )
};

export default AccordionFaq;