import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';

export const Title = styled.h2`
    color: ${({ theme }) => theme.color.primaryBlue};
    font-size: ${({ theme }) => theme.font.size.heading};
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
`;

export const ContentWrapper = styled.div`
    display: grid;
`;

export const StyledImage = styled(GatsbyImage)`
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    width: 100%;
    text-align: center;
`;

export const StyledButton = styled(Link)`
  color: ${({ theme }) => theme.color.darkBlue};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.color.primaryBlue};
  font-weight: 700;
  width: 180px;
  padding: 10px 20px;
  transition: all 0.3s;
  margin: 40px auto;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.primaryBlue};
  }
`;