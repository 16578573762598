import React from "react";
import { SectionWrapper } from "../../../assets/styles/ThemeConfig";
import { Title, TextWrapper, StyledImage, ContentWrapper, Paragraph, ParagraphList, ParagraphWithIcon, AboutUsFooterTextContainer } from './AboutUs.styled';
import { graphql, useStaticQuery } from "gatsby";
import CheckMark from '../../../assets/icons/check-mark.svg';

const query = graphql`
  {
    allContentfulZdjeciaStronaGlownaONas {
        edges {
          node {
            zdjecieONas {
              gatsbyImageData
            }
            zdjecieDlaczegoWarto {
              gatsbyImageData
            }
          }
        }
      }
}
`

export const AboutUs = () => {
    const data = useStaticQuery(query);
    return (
        <>
            <SectionWrapper id="about-us">
                <Title>O nas</Title>
                <ContentWrapper>
                    <TextWrapper>
                        <Paragraph>Dave Wave prowadzi profesjonalne zajęcia nauki gry na gitarze. Szkoła znajduje się we Wrocławiu, dzielnica Jagodno.</Paragraph>
                        <Paragraph>
                            Założycielem jest Dawid Mazurek - gitarzysta, producent muzyczny oraz realizator dźwięku. Dawid ukończył Akademię Muzyczną w Katowicach, gdzie zdobył tytuł magistra sztuki. Dodatkowo ukończył szkołę Produkcji Muzyki Nowoczesnej oraz instytut jazzu i muzyki rozrywkowej w Nysie (tytuł licencjat), gdzie otrzymał stypendium rektora za wyjątkowe wyniki w nauce. Od wielu lat działa jako aktywnie jako gitarzysta sesyjny oraz pedagog. Tworzył muzykę dla Teatru, spoty reklamowe, jingle oraz wiele, wiele innych.
                        </Paragraph>
                    </TextWrapper>
                    <StyledImage image={data.allContentfulZdjeciaStronaGlownaONas.edges[0].node.zdjecieONas.gatsbyImageData} imgStyle={{ objectPosition: 'top' }} alt={'Zdjęcie przedstawiające Dawida Mazurka z DaveWaveStudio'}/>
                </ContentWrapper>
                <Title>Dlaczego warto?</Title>
                <ContentWrapper className="second-content-wrapper">
                    <StyledImage image={data.allContentfulZdjeciaStronaGlownaONas.edges[0].node.zdjecieDlaczegoWarto.gatsbyImageData} className="second-content" imgStyle={{ objectPosition: 'left' }} alt={'Zdjęcie przedstawiające studio DaveWaveStudio'}/>
                    <ParagraphList>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Otrzymujesz indywidualny tok nauczania dostosowany do Twoich potrzeb, tempa nauki oraz muzyki, którą lubisz.
                            </Paragraph>
                        </ParagraphWithIcon>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Główna idea zajęć to ciągła motywacja, a nie ocena.
                            </Paragraph>
                        </ParagraphWithIcon>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Zajęcia prowadzone są przy użyciu profesjonalnego sprzętu w zaadaptowanym akustycznie domowym studiu nagrań.
                            </Paragraph>
                        </ParagraphWithIcon>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Oprócz materiałów w formie papierowej będziesz otrzymywać filmiki z zajęć, aby jeszcze bardziej zwiększyć skuteczność zajęć.
                            </Paragraph>
                        </ParagraphWithIcon>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Specjalnie dla Ciebie opracujemy utwory Twoich ulubionych artystów, jeśli nie ma do nich dostępnych materiałów.
                            </Paragraph>
                        </ParagraphWithIcon>
                    </ParagraphList>
                </ContentWrapper>
            </SectionWrapper>
            <AboutUsFooterTextContainer>
                <Paragraph>
                    Będziesz mieć możliwość nagrywania swoich postępów gitarowych w formie audio/wideo oraz możliwość wystąpienia na prawdziwej scenie, co dla osoby uczącej się gry na instrumencie, jest świetnym doświadczeniem.
                </Paragraph>
            </AboutUsFooterTextContainer>
        </>
    )
};
