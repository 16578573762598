import styled from "styled-components";

export const HeroPlaceholder = styled.div`
    max-width: 100%;
    background-color: ${({ theme }) => theme.color.lightGrayText};
    position: relative;

    video {
        width: 100%;
        height: 100%;
    }
`;

export const SocialMediaIconsContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 30%;
    right: 0;
`;