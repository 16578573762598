import React, { useState, useEffect } from "react"
import { SectionWrapper } from "../../../assets/styles/ThemeConfig";
import { StyledButton, Title, StyledButtonContainer } from "./PhotoGallery.styled";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { graphql } from "gatsby";

const query = graphql`
{
  allContentfulZdjeciaStronaGlownaZdjecia {
    nodes {
      zdjeciaStronaGlowna {
        gatsbyImageData
      }
    }
  }
}
`
export const PhotoGallery = () => {
  const [finalGallery, setFinalGallery] = useState([]);

  let assetsFromContentful;
  let photosUrls = [];

  useEffect(() => {
    // TUTAJ POBIERA MI ASSETY Z KONKRETNEGO CONTENT TYPU

    /* https://cdn.contentful.com/spaces/${spaceId}/environments/${environmentsName}/entries?access_token=${accesToken}&include=2&content_type=${contentTypeId} 
    
    Content Type ID - brane z listy contentów -> zakładka Info
    */
    fetch(`https://cdn.contentful.com/spaces/mf4yl096t3pn/environments/master/entries?access_token=S5Aa5RVFk8LJmSgIN9EGFcchcAFWi2mORl1MHMexpw8&include=2&content_type=zdjeciaStronaGlownaZdjecia`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        assetsFromContentful = resultData.includes.Asset;

        for (const asset of assetsFromContentful) {
          photosUrls.push(asset.fields.file.url);
        }
        setFinalGallery(photosUrls);
      })
  }, [])


  return (
    <>
      <SectionWrapper id="photo-gallery">
        <Title>Zdjęcia</Title>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
        >
          <Masonry gutter={'10px'}>
            {finalGallery.map((image, index) => (
              <img src={`https:${image}`} style={{ maxWidth: 350, objectFit: 'contain' }} alt={`Zdjęcie przedstawiające ucznia nr ${index} grającego na gitarze `} key={image} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
        <StyledButtonContainer>
          <StyledButton to="/lekcje-gry-na-gitarze/zdjecia">Zobacz więcej</StyledButton>
        </StyledButtonContainer>
      </SectionWrapper>
    </>
  )
}