import React, { useState, useEffect } from "react"
import { SectionWrapper } from "../../../assets/styles/ThemeConfig";
import { Title, StyledImageMobile, ImagesContainer } from "./PriceList.styled";

export const PriceList = () => {
  const [finalGallery, setFinalGallery] = useState([]);

  useEffect(() => {
    // TUTAJ POBIERA MI ASSETY Z KONKRETNEGO CONTENT TYPU

    /* https://cdn.contentful.com/spaces/${spaceId}/environments/${environmentsName}/entries?access_token=${accesToken}&include=2&content_type=${contentTypeId} 
    
    Content Type ID - brane z listy contentów -> zakładka Info
    */
    fetch(`https://cdn.contentful.com/spaces/mf4yl096t3pn/environments/master/entries?access_token=S5Aa5RVFk8LJmSgIN9EGFcchcAFWi2mORl1MHMexpw8&include=2&content_type=zdjeciaStronaGlownaCennik`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        const assetsFromContentful = resultData.includes.Asset;
        const photosUrls = [];
        const sortedAssets = assetsFromContentful.sort((a, b) => a.fields.title.slice(-1) - b.fields.title.slice(-1));

        for (const asset of sortedAssets) {
          photosUrls.push(asset.fields.file.url);
        }

        setFinalGallery(photosUrls);
      })
  }, [])

  return (
      <SectionWrapper id="price-list">
        <Title>Cennik</Title>
        <ImagesContainer>
          {finalGallery.map((image, index) => (
            <StyledImageMobile
              key={image}
              src={`https:${image}`}
              alt={`zdjęcie cennika ${index}`}
            />
          ))}
        </ImagesContainer>
      </SectionWrapper>
  )
}