import React from "react";
import { HeroPlaceholder, SocialMediaIconsContainer } from './Hero.styled';
import HeroVideo from '../../../assets/images/hero-video.mp4'
import { FacebookIcon } from "../FacebookIcon/FacebookIcon";
import { InstagramIcon } from "../InstagramIcon/InstagramIcon";
import { YoutubeIcon } from "../YoutubeIcon/YoutubeIcon";

const Hero = () => {
    return <HeroPlaceholder>
        <video autoPlay muted loop playsInline>
            <source src={HeroVideo} type="video/mp4"/>
        </video>
        <SocialMediaIconsContainer>
            <FacebookIcon />
            <InstagramIcon />
            <YoutubeIcon />
        </SocialMediaIconsContainer>
    </HeroPlaceholder>
}

export default Hero;