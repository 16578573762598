import React from "react";
import { SectionWrapper } from "../../../assets/styles/ThemeConfig";
import { AuthorImage, StyledButtonContainer, StyledButton, CarouselContainer, OpinionAuthorDetails, OpinionAuthorWrapper, OpinionAuthorDetailsContainer, OpinionAuthorName, OpinionDescription, OpinionTitle, OpinionWrapper, Title } from "./Opinions.styled";
import { graphql, useStaticQuery } from "gatsby";
import { Carousel } from 'react-responsive-carousel';
import { GOOGLE_REVIEWS_PAGE } from "../../../helpers/socialMediaUrls";

const query = graphql`
{
    allGooglePlacesReview {
        edges {
          node {
            author_name
            id
            text
            language
            rating
            profile_photo_url
          }
        }
      }
  }
`

const Opinions = () => {
    const data = useStaticQuery(query);
    return (
        <>
            <SectionWrapper>
                <Title>Opinie</Title>
                <CarouselContainer>
                    <Carousel showThumbs={false}>
                        {data.allGooglePlacesReview.edges.map(googleReview => {
                            return (
                                <OpinionWrapper key={googleReview.node.id}>
                                    <OpinionTitle>{googleReview.node.author_name}</OpinionTitle>
                                    <OpinionDescription>{googleReview.node.text}</OpinionDescription>
                                    <OpinionAuthorWrapper>
                                        <AuthorImage src={googleReview.node.profile_photo_url} alt={`Opinia ${googleReview.node.author_name} o DaveWaveStudio`}/>
                                        <OpinionAuthorDetailsContainer>
                                            <OpinionAuthorName>{googleReview.node.author_name}</OpinionAuthorName>
                                            <OpinionAuthorDetails>Ocena: {googleReview.node.rating}</OpinionAuthorDetails>
                                        </OpinionAuthorDetailsContainer>
                                    </OpinionAuthorWrapper>
                                </OpinionWrapper>
                            )
                        })}
                    </Carousel>
                </CarouselContainer>
                <StyledButtonContainer>
                    <StyledButton href={GOOGLE_REVIEWS_PAGE} target='_blank'>Zobacz więcej</StyledButton>
                </StyledButtonContainer>
            </SectionWrapper>
        </>
    )
}

export default Opinions;