import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const Title = styled.h2`
    color: ${({ theme }) => theme.color.primaryBlue};
    font-size: ${({ theme }) => theme.font.size.heading};
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
`;

export const ContentWrapper = styled.div`
    display: grid;
`;

export const StyledImage = styled(GatsbyImage)`
`;

export const StyledImageMobile = styled.img`
    width: 305px !important;
    margin: 0 auto;

    @media screen and (min-width: 840px) {
        width: 305px !important;
    }

    @media screen and (min-width: 1500px) {
        margin: 0;
    }
`;

export const ImagesContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1200px) {
        flex-direction: row;
        flex-wrap: nowrap;
    }
`;