import styled from "styled-components";

export const Title = styled.h2`
    color: ${({ theme }) => theme.color.primaryBlue};
    font-size: ${({ theme }) => theme.font.size.heading};
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
`;

export const ContentWrapper = styled.div`
    display: grid;
`;

export const OpinionWrapper = styled.div`
    max-width: 460px;
    margin: 0 auto;
`;

export const OpinionTitle = styled.h3`
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.font.size.opinionTitle};
    text-transform: uppercase;
    font-weight: 700;
    text-align: left;
`;

export const OpinionDescription = styled.p`
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.font.size.paragraph};
    text-align: left;
`;

export const OpinionAuthorWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const OpinionAuthorDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OpinionAuthorName = styled(OpinionDescription)`
    font-weight: 700;
    margin: 0;
`;

export const OpinionAuthorDetails = styled.p`
    color: ${({ theme }) => theme.color.lightGrayText};
    font-size: ${({ theme }) => theme.font.size.opinionAuthorDetails};
    margin: 0;
`;

export const AuthorImage = styled.img`
    width: 52px !important;
    height: 52px;
    margin-right: 10px;
`;

export const CarouselContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    .carousel-root {
        .carousel.carousel-slider {
            position: relative;
            min-height: 540px;
            .control-dots {
                .dot {
                    background-color: ${({ theme }) => theme.color.primaryBlue};
                }
            }
            .control-arrow {
                opacity: 1;
                &:hover {
                    background: transparent;
                }
                &.control-next {
                    position: absolute;
                    right: 0;
                    top: 0;
                    &::before {
                    border-left: 8px solid ${({ theme }) => theme.color.darkBlue};
                    }
                }

                &.control-prev {
                    position: absolute;
                    left: 0;
                    top: 0;

                    &::before {
                    border-right: 8px solid ${({ theme }) => theme.color.darkBlue};
                    }
                }
            }

            .carousel-status {
                display: none;
            }
        }
    }
`;

export const StyledButtonContainer = styled.div`
    width: 100%;
    display: flex;
`;

export const StyledButton = styled.a`
  color: ${({ theme }) => theme.color.darkBlue};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.color.primaryBlue};
  font-weight: 700;
  width: 180px;
  padding: 10px 20px;
  transition: all 0.3s;
  margin: 40px auto;
  cursor: pointer;
  text-decoration: none;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.primaryBlue};
  }
`;