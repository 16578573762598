import styled, { keyframes } from "styled-components";

export const Title = styled.h2`
    color: ${({ theme }) => theme.color.primaryBlue};
    font-size: ${({ theme }) => theme.font.size.heading};
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
`;

export const ContentWrapper = styled.div`
    display: grid;
`;

export const AccordionDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.darkGray};
    margin-bottom: 40px;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const showBox = keyframes`
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
`;

export const showContent = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-2%);
  }
  100% {
    opacity: 100%;
    transform: translateX(0);
  }
`;

export const StyledDetails = styled.details`
  margin: 30px 0;
  position: relative;
  summary {
    font-size: ${({ theme }) => theme.font.size.accordionTitle};
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    list-style: none;
    cursor: pointer;
  }
  summary::after {
    position: absolute;
    right: 0px;
    display: inline-block;
    content: '';
    background-image: url('../../faq-chevron.svg');
    background-repeat: no-repeat;
    margin-left: 20px;
    transition: transform 0.25s ease-in-out;
    width: 20px;
    height: 20px;
  }
  summary:focus-visible {
    outline: 3px solid #f9e852;
  }
  &[open] {
    summary::after {
      transform: rotate(-180deg);
    }
  }
  
  // Firefox animation workaround
  &[open] div {
    display: block;
  }
  div {
    display: none;
    transform: scaleY(0);
    transform-origin: 0 0;
    animation: 0.3s ease-in-out 1 forwards ${showBox};
    font-size: ${({ theme }) => theme.font.size.paragraph};
    line-height: 1.5;
    color: ${({ theme }) => theme.color.accordionBackground};
    p {
      opacity: 0;
      animation: 0.2s 0.3s ease-in 1 forwards ${showContent};
      font-size: ${({ theme }) => theme.font.size.paragraph};
    }
  }
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    summary::-webkit-details-marker {
      display: none;
    }
    
    summary:focus {
      outline: 3px solid #f9e852;
    }
  }}
`;