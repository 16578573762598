import React from "react";
import { Title, StyledIframe, CarouselContainer, StyledButtonContainer, StyledButton } from "./YoutubeSlider.styled";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { SectionWrapper } from "../../../assets/styles/ThemeConfig";
import { YOUTUBE_URL } from "../../../helpers/socialMediaUrls";

const YoutubeSlider = () => {
    return (
        <>
            <SectionWrapper>
                <Title>Video</Title>
                <CarouselContainer>
                    <Carousel showThumbs={false}>
                            <StyledIframe className="youtube-video-iframe" width="560" height="315" src="https://www.youtube.com/embed/xH2PSEMEmEI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></StyledIframe>
                            <StyledIframe width="560" height="315" src="https://www.youtube.com/embed/HdmTu8XVeVc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></StyledIframe>
                            <StyledIframe width="560" height="315" src="https://www.youtube.com/embed/kUppHsvfMoI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></StyledIframe>
                    </Carousel>
                </CarouselContainer>
            </SectionWrapper>
            <StyledButtonContainer>
                <StyledButton href={YOUTUBE_URL} target="_blank">Zobacz więcej</StyledButton>
            </StyledButtonContainer>
        </>
    )
}

export default YoutubeSlider;