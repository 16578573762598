export const data = [
    {
        title: 'Kto może wziąć udział w zajęciach? Jaki jest przedział wiekowy?',
        content: `W zajęciach udział może wziąć każdy powyżej 10 roku życia.`
    },
    {
        title: 'Czy potrzebna jest podstawowa wiedza muzyczna aby rozpocząć naukę na instrumencie? ',
        content: `Nie, możemy zacząć od podstaw.`
    },
    {
        title: 'Czy zapisując się na zajęcia muszę posiadać swoją gitarę? ',
        content: `Nie, jeśli nie masz gitary to Dave Wave wypożyczy Ci instrument na start.`
    },
    {
        title: 'Ile razy w tygodniu odbywają się zajęcia?',
        content: `Zajęcia odbywają się minimum raz w tygodniu i trwają 60 minut. Istnieje możliwość dwóch lub trzech lekcji tygodniowo.`
    },
    {
        title: 'Czy zajęcia mają charakter indywidualny?',
        content: `Tak, są to zajęcia indywidualne.`
    },
    {
        title: 'Czy jest obowiązek wzięcia udziału w nagraniach audio-video oraz obowiązek wystąpienia na koncercie?',
        content: `Nie, bardzo do tego zachęcamy, natomiast nie ma takiego obowiązku.`
    },
    {
        title: 'Jak długo powinienem chodzić na zajęcia?',
        content: `Wszystko zależy od tego, jakie umiejętności chcesz nabyć i jaki poziom gry osiągnąć. Średni czas nauki uczniów w Dave Wave to trzy lata.`
    },
    {
        title: 'Po jakim czasie będę widział/a efekty swojej gry?',
        content: `Efekty widoczne są u każdego w innym czasie. Zależy to od predyspozycji, ale przede wszystkim ilości czasu, który dana osoba może poświęcić na grę na instrumencie w domu. Dodam, że w Dave Wave Studio uczniowie po 3 miesiącach nauki potrafią zagrać swoje ulubione utwory.`
    },
    {
        title: 'Gdzie odbywają się zajęcia?',
        content: `Zajęcia odbywają się w profesjonalnym, domowym studiu nagrań - dzielnica Jagodno, Wrocław.`
    },
    {
        title: 'W jaki sposób uiszcza się opłatę?',
        content: `Opłatę wnosi się w formie przelewu, po otrzymaniu faktury.`
    },
    {
        title: 'Czy jest możliwość wystawienia FV na firmę? ',
        content: `Tak.`
    },
    {
        title: 'Czy w zajęciach mogą uczestniczyć dwie osoby grające?',
        content: `W wyjątkowych sytuacjach - tak.`
    },
    {
        title: 'Przyprowadzam bliską osobę na zajęcia, czy jest miejsce gdzie mogę poczekać?',
        content: `Oczywiście, mamy wygodną kanapę oraz kawę z ekspresu, która umili oczekiwanie :).`
    },
    {
        title: 'Jak wyglada kwestia parkingu?',
        content: `Parking jest udostępniony dla uczniów na osiedlu. Więcej informacji u nauczyciela.`
    },
    {
        title: 'Co się stanie, jeśli nie będę mógł dotrzeć na zajęcia z mojego powodu?',
        content: `Jeśli poinformowałeś/łaś 24 godziny wcześniej o nieobecności, zajęcia zostaną przesunięte na inny termin. W innych wyjątkowych sytuacjach oczywiście zajęcia także mogą być przesunięte - podchodzimy do tematu po prostu „po ludzku”.`
    },
    {
        title: 'Co się stanie, jeśli zajęcia się nie odbędą z Waszego powodu?',
        content: `Wówczas zajęcia są przekładane na inny termin. W wyjątkowych sytuacjach faktura zostanie pomniejszona o koszt zajęć, które się nie odbyły.`
    },
    {
        title: 'Jak wyglada kwestia wakacji?',
        content: `Podczas wakacji zajęcia są nieobowiązkowe. Rok szkolny trwa od pierwszego września do końca czerwca.`
    },
    {
        title: 'Czy mogę zmienić dzień oraz godzinę zajęć w ciągu roku?',
        content: `Tak, o ile będzie dostępny inny termin.`
    },
    {
        title: 'Jak długi jest okres wypowiedzenia umowy?',
        content: `Czas wypowiedzenia umowy wynosi miesiąc (30 dni).`
    },
    {
        title: 'Czy doradzacie w zakupie gitary? Czy można u Was wymienić struny w gitarze?',
        content: `Tak, pomagamy w wyborze gitary, a także w doborze odpowiednich strun itp. Pomożemy także w ich wymianie.`
    },
    {
        title: 'Co dodatkowego czeka mnie na miejscu?',
        content: `Kawa, coś słodkiego i mnóstwo pozytywnej energii.`
    }
];